import ErrorSlate from '@shared/ErrorSlate';
import { ContentWrapper } from '@shared/PageLayout';

export default function Custom404() {
  return (
    <ContentWrapper>
      <ErrorSlate
        title="404"
        status="404"
        customErrorMessage={'Page Not Found'}
      />
    </ContentWrapper>
  );
}
